import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Emil Sonesson" />
    <h1 className="text-5xl text-gray-700 text-center">Emil Sonesson</h1>
    <p className="text-lg text-gray-700 text-center mb-2">
      Portfolio under construction.
    </p>
    <footer>
      <p className="mt-6 text-sm text-gray-700 text-center">
        © {new Date().getFullYear()} Emil Sonesson
      </p>
    </footer>
  </Layout>
)

export default IndexPage
